<template>
  <step-lesson-layout :title="$t('module1.part1.lesson1.title')" :title-annotation="$t('module1.part1.lesson1.titleAnnotation')">
    <template v-slot:content>
      <!-- BLOCK 1 -->
      <training-secondary-block :title="$t('module1.part1.lesson1.block1.title')" :annotation="$t('module1.part1.lesson1.block1.annotation')">
        <template v-slot:content>
          <div class="training-question-block">
            <p class="training-question-title">{{ $t('module1.part1.lesson1.block1.quizzLabel') }}</p>
            <p class="training-question-label">{{ $t('module1.part1.lesson1.block1.quizzQuestion') }}</p>
            <div class="training-question-content">
              <quizz-image-only :answers="quizz1Answers" :right-answer="quizz1RightAnswer"></quizz-image-only>
            </div>
            <p class="source">
              <span>{{ $t('global.source') }} : </span>
              <a href="https://www.statistiques.developpement-durable.gouv.fr/bilan-energetique-de-la-france-pour-2019" target="_blank" rel="noopener">
                {{ $t('module1.part1.lesson1.block1.source') }}
              </a>
            </p>
          </div>
        </template>
      </training-secondary-block>

      <!-- BLOCK 2 -->
      <training-secondary-block :title="$t('module1.part1.lesson1.block2.title')" :annotation="$t('module1.part1.lesson1.block2.annotation')">
        <template v-slot:content>
          <div class="training-question-block">
            <p class="training-question-title">{{ $t('module1.part1.lesson1.block2.quizzLabel') }}</p>
            <p class="training-question-label">{{ $t('module1.part1.lesson1.block2.quizzQuestion') }}</p>
            <div class="training-question-content">
              <quizz-image-only :answers="quizz2Answers" :right-answer="quizz2RightAnswer"></quizz-image-only>
            </div>
            <p class="source">
              <span>{{ $t('global.source') }} : </span>
              <a href="https://www.notre-environnement.gouv.fr/donnees-et-ressources/ressources/graphiques/article/repartition-sectorielle-des-emissions-de-gaz-a-effet-de-serre-en-france-en-2017" target="_blank" rel="noopener">
                {{ $t('module1.part1.lesson1.block2.source') }}
              </a>
            </p>
          </div>
        </template>
      </training-secondary-block>

      <!-- BLOCK 3 -->
      <training-secondary-block :title="$t('module1.part1.lesson1.block3.title')" :annotation="$t('module1.part1.lesson1.block3.annotation')">
        <template v-slot:content>
          <div class="training-question-block">
            <p class="training-question-title">{{ $t('module1.part1.lesson1.block3.quizzLabel') }}</p>
            <p class="training-question-label">{{ $t('module1.part1.lesson1.block3.quizzQuestion') }}</p>
            <div class="training-question-content">
              <quizz-image-only :answers="quizz3Answers" :right-answer="quizz3RightAnswer"></quizz-image-only>
            </div>
            <p class="source">
              <span>{{ $t('global.source') }} : </span>
              <a href="https://www.onpe.org/sites/default/files/onpe_tableau_de_bord_2021_s2_vf.pdf" target="_blank" rel="noopener">
                {{ $t('module1.part1.lesson1.block3.source') }}
              </a>
            </p>
          </div>
        </template>
      </training-secondary-block>

      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout></template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import QuizzImageOnly from '@/components/training/QuizzImageOnly'
import TrainingSecondaryBlock from '@/components/layout/TrainingSecondaryBlock'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module1Part1Lesson1',
  components: { AppButtonLayout, TrainingSecondaryBlock, QuizzImageOnly, StepLessonLayout },
  emits: ['next-tab'],
  data () {
    return {
      quizz1Answers: [
        {
          name: 'low',
          label: this.$t('module1.part1.lesson1.block1.quizzAnswerLow'),
          image: require('@/assets/module1/part1/national-energy-conso-low.svg')
        },
        {
          name: 'medium',
          label: this.$t('module1.part1.lesson1.block1.quizzAnswerMedium'),
          image: require('@/assets/module1/part1/national-energy-conso-medium.svg')
        },
        {
          name: 'high',
          label: this.$t('module1.part1.lesson1.block1.quizzAnswerHigh'),
          image: require('@/assets/module1/part1/national-energy-conso-high.svg')
        }
      ],
      quizz1RightAnswer: 'high',
      quizz2Answers: [
        {
          name: 'low',
          label: this.$t('module1.part1.lesson1.block2.quizzAnswerLow'),
          image: require('@/assets/module1/part1/national-emission-low.svg')
        },
        {
          name: 'medium',
          label: this.$t('module1.part1.lesson1.block2.quizzAnswerMedium'),
          image: require('@/assets/module1/part1/national-emission-medium.svg')
        },
        {
          name: 'high',
          label: this.$t('module1.part1.lesson1.block2.quizzAnswerHigh'),
          image: require('@/assets/module1/part1/national-emission-high.svg')
        }
      ],
      quizz2RightAnswer: 'medium',
      quizz3Answers: [
        {
          name: 'low',
          label: this.$t('module1.part1.lesson1.block3.quizzAnswerLow'),
          image: require('@/assets/module1/part1/national-ener-preca-low.svg')
        },
        {
          name: 'medium',
          label: this.$t('module1.part1.lesson1.block3.quizzAnswerMedium'),
          image: require('@/assets/module1/part1/national-ener-preca-medium.svg')
        },
        {
          name: 'high',
          label: this.$t('module1.part1.lesson1.block3.quizzAnswerHigh'),
          image: require('@/assets/module1/part1/national-ener-preca-high.svg')
        }
      ],
      quizz3RightAnswer: 'high'
    }
  }
}
</script>
