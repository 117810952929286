<template>
  <div class="training-secondary-block">
    <div class="head">
      <slot name="head">
        <p class="title">
          {{ title }}
        </p>
        <p class="annotation">
          {{ annotation }}
        </p>
      </slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrainingSecondaryBlock',
  props: {
    title: {
      type: String,
      required: false
    },
    annotation: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.training-secondary-block {
  background-color: $c-secondary;
  padding: $space-m $space-s;
  border-radius: $radius;
  .head {
    padding: 0 $space-m;
  }
  .title {
    color: #fff;
    font-family: $ff-big;
    font-weight: $fw-l;
    font-size: $fz-xll;
  }
  .annotation {
    margin: $space-m 0;
    color: #fff;
  }
}

@media (min-width: $bp-tablet) {
  .training-secondary-block {
    display: flex;
    align-items: flex-start;
    padding: $space-m;
    .head {
      margin-right: $space-l;
      padding: 0;
    }
  }
}
</style>
